import { validatePhoneNumber } from '@/features/misc/data/phone-number-regex';
import { z } from 'zod';

const createUserSchema = z.object({
  username: z.string().refine((value) => value !== '', {
    message: 'user_name_required_validation_text',
  }),
  name: z.string().refine((value) => value !== '', {
    message: 'name_required_validation_text',
  }),
  role: z.number(),
  usernameVerified: z.boolean().refine((value) => value !== null, {
    message: 'username_verified_required_validation_text',
  }),
  emailVerified: z.boolean(),
  telephone: z
    .string()
    .optional()
    .refine((value) => !value || validatePhoneNumber(value), {
      message: 'invalid_telephone_number',
    })
    .or(z.literal('')),
});

export { createUserSchema };
