import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Menu } from './Menu';
import { useIsOverflow } from '@/hooks/use-is-overflow';
import { cn } from '@/utils';
import { ConfirmProvider } from '@/providers/confirm';
import { useTimePeriodRestrictions } from '@/hooks/use-time-period-restrictions';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Footer } from '@/features/misc/components/footer';

const WIDE_ROUTES = ['events/edit', 'events/create'];

export function Layout() {
  const [_, ref] = useIsOverflow();

  const { pathname } = useLocation();

  const { isPortalClosed } = useTimePeriodRestrictions();

  if (isPortalClosed)
    return (
      <div className={cn(' h-screen flex  organizer')}>
        <main ref={ref} className={cn('w-full h-full overflow-y-auto items-center justify-center')}>
          <div className={cn('lg:p-4 p-6 w-full max-w-[1440px]  mx-auto mt-20')}>
            <div className="flex justify-center items-center h-full">
              <Card className="w-full max-w-lg">
                <CardHeader>
                  <CardTitle>
                    <Label name="portal_closed_title" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <Label name="portal_closed" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
                </CardContent>
              </Card>
            </div>
          </div>
        </main>
      </div>
    );

  return (
    <ConfirmProvider>
      <div className={cn('min-h-screen w-full flex flex-col overflow-x-hidden organizer')}>
        <Menu />
        <main ref={ref} className={cn('flex-1  max-h-[calc(100lvh_-_52px)] lg:max-h-[calc(100lvh_-_121px)] overflow-y-auto', !WIDE_ROUTES.some(route => pathname.includes(route)) && 'stable-scroll')}>
          <div className={cn(WIDE_ROUTES.some(route => pathname.includes(route)) ? 'w-full' : 'lg:p-4 p-6 w-full max-w-[1440px]  mx-auto')}>
            <Suspense fallback={<div />}>
              <Outlet />
            </Suspense>
          </div>
          {pathname === '/' && (
            <div className="w-screen -ml-[17px] mt-10">
              <Footer />
            </div>
          )}
        </main>
      </div>
    </ConfirmProvider>
  );
}
