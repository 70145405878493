import { DataTable } from '@/components/ui/data-table';
import { Separator } from '@/components/ui/separator';
import { useLocations } from '../api/getLocations';
import { columns } from '../components/location-columns';
import { LocationFilters } from '../components/location-filters';
import { Row } from '@tanstack/react-table';
import { TLocation } from '../types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

export function Locations() {
  const { data: locations = [] } = useLocations({});

  const sortedLocations = locations.sort((a, b) => {
    const aAddressNumber = parseInt(a.name.match(/\d+/)?.[0] ?? '0', 10);
    const bAddressNumber = parseInt(b.name.match(/\d+/)?.[0] ?? '0', 10);

    const aAddressName = a.name
      .substring(0, a.name.indexOf(aAddressNumber.toString()) + aAddressNumber.toString().length)
      .replace(/\d+/g, '')
      .trim();

    const bAddressName = b.name
      .substring(0, b.name.indexOf(bAddressNumber.toString()) + bAddressNumber.toString().length)
      .replace(/\d+/g, '')
      .trim();

    if (aAddressName === bAddressName) {
      return aAddressNumber - bAddressNumber;
    }

    return a.name.localeCompare(b.name);
  });

  return (
    <div>
      <div className="flex items-center justify-between pb-4">
        <h2 className="text-lg font-semibold">
          <Label name="locations_page_title" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
        </h2>
        <Link to="/locations/create">
          <Button className="ml-4">
            <Plus className="h-4 w-4 mr-1" />
            <Label name="create_location_button_text" groupName={LabelGroup.ADMIN_LOCATION_LIST} />
          </Button>
        </Link>
      </div>
      <Separator />
      <div>
        <DataTable
          columns={columns}
          data={sortedLocations}
          FacetedFilters={LocationFilters}
          config={{
            getSearchFields: row => [(row as Row<TLocation>).original.name, (row as Row<TLocation>).original.category]
          }}
        />
      </div>
    </div>
  );
}
