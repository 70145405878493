import { DataTableFacetedFilter } from '@/components/ui/data-table-faceted-filter';
import { statuses } from '@/features/admin/data/event-data';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useManagers } from '@/features/admin/api/getManagers';
import { useReactTable } from '@tanstack/react-table';
import { useArchivedEvents } from '@/features/admin/api/get-archived-events';
import { Manager } from '@/features/admin/types';

interface LocationFiltersProps {
  table: ReturnType<typeof useReactTable>;
}

export function ArchivedEventFilters({ table }: LocationFiltersProps) {
  const { data: archivedEvents = [] } = useArchivedEvents({
    config: {
      staleTime: Infinity, // Keeps data fresh forever
      cacheTime: Infinity, // Prevents garbage collection
      enabled: false
    }
  });

  const { data: _managers = [] } = useManagers({});

  const organizers = [...new Set(archivedEvents.map(event => event.organizerName))];
  const years = [...new Set(archivedEvents.map(event => event.yearId))];
  const managers = _managers.reduce((acc: { value: string | number; label: string }[] = [], manager: Manager) => {
    if (!acc.some((manager: any) => manager.value === manager.managerId)) {
      acc.push({ value: manager.managerId, label: manager.username });
    }
    return acc;
  }, []);

  return (
    <div className="space-x-2 mx-2">
      <DataTableFacetedFilter column={table.getColumn('status')} TitleComponent={<Label name="status" groupName={LabelGroup.MANAGER_EVENT_LIST} />} options={statuses} />
      <DataTableFacetedFilter
        column={table.getColumn('yearId')}
        TitleComponent={<Label name="year" groupName={LabelGroup.MANAGER_EVENT_LIST} />}
        options={
          years?.map(year => ({
            value: year?.toString() || '',
            label: year?.toString() || ''
          })) || []
        }
      />
      <DataTableFacetedFilter
        column={table.getColumn('organizerName')}
        TitleComponent={<Label name="organizer" groupName={LabelGroup.MANAGER_EVENT_LIST} />}
        options={
          organizers?.map(organizer => ({
            value: organizer,
            label: organizer
          })) || []
        }
      />
      <DataTableFacetedFilter column={table.getColumn('assignedUserId')} TitleComponent={<Label name="managers" groupName={LabelGroup.MANAGER_EVENT_LIST} />} options={managers} />
    </div>
  );
}
